import { Box, GridItem, Grid, Text, Spacer } from "@chakra-ui/react";
import pic from "./logo.png";
import cropmodeling from "./CropModeling.png";
import economicModeling from "./Economicmodeling.png";
import icaModeling from "./lcaModeling.png";
import stakeholdersExtension from "./StakeholdersExtension.png";
import { Select, Link } from "@chakra-ui/react";
import arkansas from "./arkansas_logo.png";
import ifpri_logo from "./ifpri_logo.png";
import illinois_logo from "./illinois_logo.png";
import ilsi_logo from "./ilsi_logo.png";
import nifa_logo from "./nifa_logo.png";
import uf_logo from "./uf_logo.png";
import waees_logo from "./waees_logo.png";
import washingtonstate_logo from "./washingtonstate_logo.png";
import eshwar from "./eshwar_profile_pic.jpeg";
import julien from "./julien_profile_pic.png";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { Stack, HStack, VStack, Heading, Image } from "@chakra-ui/react";

function Feature({ title, desc, ...rest }) {
  return (
    <Box p={5} {...rest}>
      <Heading color={"#005750"} fontSize="xl">
        {title}
      </Heading>
      <Text color={"#005750"} textAlign={"justify"} mt={4}>
        {desc}
      </Text>
    </Box>
  );
}

function Feature1({ name, title, place, ...rest }) {
  return (
    <Box {...rest}>
      <Heading color={"#005750"} fontSize="md">
        {name}
      </Heading>
      <Text color={"#005750"} fontSize="sm">
        {title}
      </Text>
      <Text color={"#005750"} fontSize="sm">
        {place}
      </Text>
    </Box>
  );
}
const Home = () => {
  return (
    <div>
      <Grid templateColumns="repeat(2, 1fr)" px={20} py={10} gap={10}>
        <GridItem>
          <Text>
            <Text textAlign={"left"} fontSize="xl">
              Team
            </Text>
            <br></br>
            <Text textAlign={"left"}>
              The project team includes scientists, extension specialists,
              practitioners, and students from the Agriculture & Food Systems
              Institute, University of Florida (UF), International Food Policy
              Research Institute (IFPRI), University of Arkansas (UARK),
              University of Illinois (UIUC), Washington State University (WSU),
              and World Agricultural Economic and Environmental Services
              (WAEES). This analog extension tool was developed at Washington
              State University.
            </Text>
            <br></br>
            <br></br>
            <Text textAlign={"left"} fontSize="xl">
              Project
            </Text>
            <br></br>
            <Text textAlign={"left"}>
              USDA/NIFA Award: No. 2017-68002-26789{" "}
            </Text>
            <Text textAlign={"left"}>
              Project details can be found{" "}
              <Link
                href="https://foodsystems.org/what-we-do/nutrition/fruit-vegetable-supply-chains/"
                color={"blue"}
                isExternal
              >
                here
                <ExternalLinkIcon mx="2px" />
              </Link>
            </Text>

            <Text textAlign={"left"}>
              For general information, contact: dgustafson@ilsi.org or
              cfraisse@ufl.edu
            </Text>
            <Text textAlign={"left"}>
              For information on the analog tool, contact: kirtir@wsu.edu or
              cekruger@wsu.edu
            </Text>
          </Text>
        </GridItem>
        <GridItem>
          <Grid templateColumns="repeat(1, 1fr)" gap={6}>
            <GridItem>
              <Text textAlign={"left"} fontSize="xl">
                Tool Description
              </Text>
              <br></br>
              <Text textAlign={"left"}>
                This tool enables the user to explore future climate analogs for
                all counties with current specialty crop production acreage in
                the continental United States. To identify analogs for a target
                county of interest, we ask the question,{" "}
                <i>
                  "are there counties in the US whose current growing conditions
                  resemble what we expect in our target county in the future?"
                </i>
                . The goal of this tool is to facilitate conversations between
                subject-matter experts and producers in target-analog pairs that
                can allow the exchange of actionable climate-change insights.
              </Text>
            </GridItem>
            <GridItem>
              <HStack>
                <VStack spacing={2}>
                  <Image borderRadius="full" boxSize="130px" src={eshwar} />
                  <Feature1
                    name="Eshwar Nag Pilli"
                    title="CS Graduate Studentat WSU"
                    place="at WSU"
                  />
                </VStack>
                <Spacer />
                <VStack spacing={2}>
                  <Image
                    borderRadius="full"
                    boxSize="130px"
                    src="https://s3.wp.wsu.edu/uploads/sites/2535/2020/08/S.-Chaudhary-396x456-1-198x198.png"
                    alt="Siddharth Chaudhary"
                  />
                  <Feature1
                    name="Siddharth Chaudhary"
                    title="Post Doctoral Researcher"
                    place="at WSU"
                  />
                </VStack>
                <Spacer />
                <VStack spacing={2}>
                  <Image
                    borderRadius="full"
                    boxSize="130px"
                    src={julien}
                    alt="Julien Pireaud"
                  />
                  <Feature1
                    name="Julien Pireaud"
                    title="Software Engineer &amp; Owner"
                    place="at NJC Software LLC"
                  />
                </VStack>
              </HStack>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>

      {/********* Scrolling Marquee ******** */}
      <Grid templateColumns="repeat(1, 1fr)" py={5}>
        <GridItem w="100%">
          <marquee behavior="scroll" direction="left">
            <HStack spacing="10">
              <Box w="100">
                <Image h={100} src={washingtonstate_logo} />
              </Box>
              <Box w="100">
                <Image h={50} src={arkansas} />
              </Box>
              <Box w="100">
                <Image h={70} src={ifpri_logo} />
              </Box>
              <Box w="100">
                <Image h={50} src={illinois_logo} />
              </Box>
              <Box w="100">
                <Image h={50} src={ilsi_logo} />
              </Box>
              <Box w="100">
                <Image h={50} src={nifa_logo} />
              </Box>
              <Box w="100">
                <Image h={50} src={uf_logo} />
              </Box>
              <Box w="100">
                <Image h={100} src={waees_logo} />
              </Box>
            </HStack>
          </marquee>
        </GridItem>
      </Grid>

      {/********* All Projects ******** */}
      <Grid templateColumns="repeat(4, 1fr)" px={20} py={10} gap={6}>
        <GridItem w="100%">
          <VStack spacing={2}>
            <Image h={100} src={cropmodeling} />
            <Feature
              title="Crop Modeling"
              desc="The University of Florida, Washington State University, and the University of Illinois at Urbana-Champaign are determining current and future climate and water availability impacts on yield and quality of selected crops."
            />
          </VStack>
        </GridItem>
        <GridItem w="100%">
          <VStack spacing={2}>
            <Image h={100} src={economicModeling} />
            <Feature
              title="Economic Modeling"
              desc="The International Food Policy Research Institute and World Agricultural Economic and Environmental Services are extrapolating current and future prices and production costs of selected crops."
            />
          </VStack>
        </GridItem>
        <GridItem w="100%">
          <VStack spacing={2}>
            <Image h={100} src={icaModeling} />
            <Feature
              title="Life-Cycle Assement (LCA) Modeling"
              desc="The University of Arkansas is examining current and potential future fruit and vegetable value chains to identify and evaluate costeffective adaptation and mitigation opportunities."
            />
          </VStack>
        </GridItem>
        <GridItem w="100%">
          <VStack spacing={2}>
            <Image h={100} src={stakeholdersExtension} />
            <Feature
              title="Stakeholders &amp; Extension"
              desc="The Agriculture &amp; Food Systems Institute, University of Florida, and Washington State University are engaging with stakeholders and decision makers to ensure models reflect realistic practices and provide actionable information."
            />
          </VStack>
        </GridItem>
      </Grid>
    </div>
  );
};

export default Home;
