import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

export function Summary(props) {
  const targetInfoData = props.targetInfoData;
  console.log(targetInfoData);
  const th_gdd = targetInfoData?.historical?.growingDegreeDay ?? "";
  const th_iap = targetInfoData?.historical?.intraAnualPrecipitation ?? "";
  const th_hdh = targetInfoData?.historical?.heatDegreeHours ?? "";
  const th_sl = targetInfoData?.historical?.seasonLength ?? "";
  const th_ch = targetInfoData?.historical?.chillHours ?? "";
  const tf_gdd = targetInfoData?.future?.growingDegreeDay ?? "";
  const tf_iap = targetInfoData?.future?.intraAnualPrecipitation ?? "";
  const tf_hdh = targetInfoData?.future?.heatDegreeHours ?? "";
  const tf_sl = targetInfoData?.future?.seasonLength ?? "";
  const tf_ch = targetInfoData?.future?.chillHours ?? "";
  const gdd = props.infoData?.growingDegreeDay ?? "";
  const iap = props.infoData?.intraAnualPrecipitation ?? "";
  const hdh = props.infoData?.heatDegreeHours ?? "";
  const sl = props.infoData?.seasonLength ?? "";
  const ch = props.infoData?.chillHours ?? "";
  return (
    <Stack direction={"column"}>
      <Text pl={2} mt={2} fontWeight={"bold"}>
        Variable Values:
      </Text>
      <Table size={"sm"}>
        <Thead xs={{ textAlign: "center" }}>
          <Tr>
            <Td></Td>
            <Td colSpan={2} fontWeight={"bold"} textAlign={"center"}>
              Target
            </Td>
            <Td fontWeight={"bold"} textAlign={"center"}>
              Analog
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={"bold"}>Variable</Td>
            <Td fontWeight={"bold"}>Historical</Td>
            <Td fontWeight={"bold"}>Future</Td>
            <Td fontWeight={"bold"}>Historical</Td>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Growing Degree Days (&deg; day C)</Td>
            <Td>{th_gdd}</Td>
            <Td>{tf_gdd}</Td>
            <Td>{gdd}</Td>
          </Tr>
          <Tr>
            <Td>Heat Degree Hours (&deg; hour C)</Td>
            <Td>{th_hdh}</Td>
            <Td>{tf_hdh}</Td>
            <Td>{hdh}</Td>
          </Tr>
          <Tr>
            <Td>Season Length (days)</Td>
            <Td>{th_sl}</Td>
            <Td>{tf_sl}</Td>
            <Td>{sl}</Td>
          </Tr>
          <Tr>
            <Td>Chill Hours (hours)</Td>
            <Td>{th_ch}</Td>
            <Td>{tf_ch}</Td>
            <Td>{ch}</Td>
          </Tr>
          <Tr>
            <Td>Intra Annual Precipitation Difference (mm)</Td>
            <Td>{th_iap}</Td>
            <Td>{tf_iap}</Td>
            <Td>{iap}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Stack>
  );
}
