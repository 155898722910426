import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Spacer,
  Table,
  Tag,
  TagLabel,
  TagRightIcon,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { first, join, map } from "lodash";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import Plot from "react-plotly.js";
import legendPic from "./legend.png";

let agUrl =
  "https://www.nass.usda.gov/Publications/AgCensus/2017/Online_Resources/County_Profiles/";

export function Analogs(props) {
  const analogsData = props.analogsData;
  const countyData = props.countyData;
  const fetchHistoricalData = props.fetchHistoricalData;

  function SummaryDataButton(props) {
    return (
      <Button
        ml={1}
        value={props.fips}
        onClick={props.callback}
        colorScheme="blue"
        size="xs"
      >
        {props.children}
      </Button>
    );
  }

  function CropChart(props) {
    return (
      <Box borderRadius="md">
        <Plot
          data={[
            {
              values: [props.fnbs, props.vegetables, props.others],
              labels: ["FNB", "Vegetable", "Others"],
              marker: {
                colors: [
                  "rgb(71, 118, 176)",
                  "rgb(227, 126, 36)",
                  "rgb(96, 159, 59)",
                ],
              },
              type: "pie",
              sort: false,
            },
          ]}
          config={{ displayModeBar: false }}
          layout={{
            width: 150,
            height: 150,
            margin: {
              l: 20,
              r: 20,
              b: 10,
              t: 30,
              pad: 4,
            },
            showlegend: false,
            // visible: false,
            textposition: "inside",
            // hoverinfo: "none",
            paper_bgcolor: "rgba(0,0,0,0)",
            plot_bgcolor: "rgba(0,0,0,0)",
            hovermode: false,
            displayModeBar: false,
          }}
        />
      </Box>
    );
  }

  function CountyTitle(props) {
    const color = props.color;
    const county = props.county;
    const showVariableValues = props.showVariableValues ?? false;
    return (
      <VStack align={"start"}>
        <HStack>
          <Tag colorScheme={color}>
            <TagLabel>
              <a
                href={`${agUrl}/${county.state}/cp${county.fips}.pdf`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <b>
                  {county.county} - {county.state}
                </b>
              </a>
            </TagLabel>
            <TagRightIcon as={ExternalLinkIcon} />
          </Tag>
          {showVariableValues && (
            <SummaryDataButton
              fips={county.fips}
              callback={e => {
                fetchHistoricalData(e.target.value);
              }}
            >
              Variable Data
            </SummaryDataButton>
          )}
        </HStack>
        <Text fontSize={"xs"} color="orange">
          {analogsData.length === 0
            ? county.county !== "Imperial"
              ? "(No acceptable analogs in speciality crop counties)"
              : "(No acceptable US analogs)"
            : ""}
        </Text>
      </VStack>
    );
  }

  function CountyCrop(props) {
    console.log("county crops", props);
    const crops = props.crops;
    const cropsByArea = props.cropsByArea;
    // cropByArea value will be undefined and not 0.0 if there is no value in the database
    if (crops.length > 0 || cropsByArea === 0.0) {
      return <Text>{join(map(crops, "name"), ", ")}</Text>;
    } else {
      return <Text fontStyle={"italic"}>Crop list not available</Text>;
    }
  }

  function TableCountyTitle(props) {
    const color = props.color;
    const county = props.county;
    const showVariableValues = props.showVariableValues ?? false;
    return (
      <Td>
        <CountyTitle
          color={color}
          county={county}
          showVariableValues={showVariableValues}
        />
      </Td>
    );
  }

  function TableCountyCrops(props) {
    const color = props.color;
    const crops = props.crops;
    const cropsByArea = props.cropsByArea;
    return (
      <Td color={color}>
        <CountyCrop crops={crops} cropsByArea={cropsByArea} />
      </Td>
    );
  }

  function TableCountyCharts(props) {
    const county = props.county;
    return (
      <Td rowSpan={2}>
        <HStack alignItems={"center"}>
          <Spacer />
          {county.cropShareByArea && (
            <CropChart
              fnbs={county.cropShareByArea.fnb}
              vegetables={county.cropShareByArea.vegetables}
              others={county.cropShareByArea.other}
            ></CropChart>
          )}
          <Spacer />
        </HStack>
      </Td>
    );
  }

  return (
    <Table size={"sm"}>
      <Tbody>
        <Tr>
          <Td colSpan={2} fontWeight={"bold"}>
            County
          </Td>
          {countyData && (
            <TableCountyTitle
              color={"green"}
              county={countyData}
              showVariableValues={false}
            />
          )}
          {(() => {
            const firstAnalog = first(analogsData);
            return map(analogsData, analogData => {
              const color =
                firstAnalog.frequency > analogData.frequency
                  ? "blackAlpha"
                  : "red";
              return (
                <TableCountyTitle
                  color={color}
                  county={analogData.analog}
                  showVariableValues={true}
                />
              );
            });
          })()}
        </Tr>
        <Tr>
          <Td rowSpan={3} fontWeight={"bold"}>
            Top Crops By Acres
          </Td>
          <Td fontWeight={"bold"}>Fruit, Nut &amp; Berries</Td>
          {countyData && (
            <TableCountyCrops
              color={"green"}
              crops={countyData.fnbs}
              cropsByArea={countyData.cropShareByArea?.fnb}
            />
          )}
          {(() => {
            const firstAnalog = first(analogsData);
            return map(analogsData, analogData => {
              const color =
                firstAnalog.frequency > analogData.frequency ? "gray" : "red";
              return (
                <TableCountyCrops
                  color={color}
                  crops={analogData.analog.fnbs}
                  cropsByArea={analogData.analog.cropShareByArea?.fnb}
                />
              );
            });
          })()}
        </Tr>
        <Tr>
          <Td fontWeight={"bold"}>Vegetables</Td>
          {countyData && (
            <TableCountyCrops
              color={"green"}
              crops={countyData.vegetables}
              cropsByArea={countyData.cropShareByArea?.vegetables}
            />
          )}
          {(() => {
            const firstAnalog = first(analogsData);
            return map(analogsData, analogData => {
              const color =
                firstAnalog.frequency > analogData.frequency ? "gray" : "red";
              return (
                <TableCountyCrops
                  color={color}
                  crops={analogData.analog.vegetables}
                  cropsByArea={analogData.analog.cropShareByArea?.vegetables}
                />
              );
            });
          })()}
        </Tr>
        <Tr>
          <Td fontWeight={"bold"}>Other Crops</Td>
          {countyData && (
            <TableCountyCrops
              color={"green"}
              crops={countyData.otherCrops}
              cropsByArea={countyData.cropShareByArea?.other}
            />
          )}
          {(() => {
            const firstAnalog = first(analogsData);
            return map(analogsData, analogData => {
              const color =
                firstAnalog.frequency > analogData.frequency ? "gray" : "red";
              return (
                <TableCountyCrops
                  color={color}
                  crops={analogData.analog.otherCrops}
                  cropsByArea={analogData.analog.cropShareByArea?.other}
                />
              );
            });
          })()}
        </Tr>
        <Tr>
          <Td rowSpan={2} fontWeight={"bold"}>
            Crop Share By Area (%)
            <br />
            <img
              style={{ minWidth: "150px", maxWidth: "150px" }}
              src={legendPic}
              alt="Pie charts legend"
            />
          </Td>
          <Td rowSpan={2}></Td>
          {countyData && <TableCountyCharts county={countyData} />}
          {(() => {
            return map(analogsData, analogData => {
              return <TableCountyCharts county={analogData.analog} />;
            });
          })()}
        </Tr>
      </Tbody>
    </Table>
  );
}
