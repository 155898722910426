import {
  Badge,
  HStack,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

function HelpLine(props) {
  const bgColor = props.bgColor ?? "white";
  const index = props.index;
  const description = props.description;
  return (
    <HStack>
      <Badge colorScheme={bgColor}>
        <Text>{index}</Text>
      </Badge>
      <Text> - {description}</Text>
    </HStack>
  );
}

export function Help() {
  return (
    <Stack direction="column" p={2}>
      <Text>
        <Text fontWeight={"bold"}>To start:</Text>Select the target state,
        county, and scenario of interest. Available counties are limited to the
        top 99% of specialty crop production areas in the continental US (680
        counties). If your target county's mid-century future has other
        specialty crop counties as acceptable historical analogs, the bottom
        panel will display.{" "}
      </Text>
      <Text>
        <OrderedList pl={5}>
          <ListItem>the analog list along with the crop mix,</ListItem>
          <ListItem>
            a link to the 2017 Agricultural Census summary for each county on
            the county name,
          </ListItem>
          <ListItem>
            a link to add the data for the analog county in the table on the
            right panel.
          </ListItem>
        </OrderedList>
      </Text>
      <Text>
        The color legend for the target county, acceptable analogs, and most
        frequent acceptable analogs are provided below.
      </Text>
      <Text fontWeight={"bold"}>County boundary color legend:</Text>
      <HelpLine
        bgColor={"green"}
        index={1}
        description={"Selected Target County"}
      />
      <HelpLine
        bgColor={"red"}
        index={2}
        description={"Most Frequent Good Analog"}
      />
      <HelpLine bgColor={"#eee"} index={3} description={"All Good Analogs"} />
      <Text fontWeight={"bold"}>Methodology:</Text>
      <Text>
        For each of the 680 specialty crop target counties in the US, analogs
        are calculated for the mid-21st-century future timeframe for two
        greenhouse gas concentration scenarios. The acceptable analog list is
        filtered to include specialty crop counties only and the crop mix based
        on the 2017 Agricultural census is displayed.
      </Text>
      <Text>
        Details of the Methodology can be found&nbsp;
        <a
          href={"/assets/methodology_analogtool.pdf"}
          rel="noopener noreferrer"
          target="_blank"
        >
          <b>here</b>
        </a>
        .
      </Text>
    </Stack>
  );
}
