import { Outlet, Link } from "react-router-dom";
import {
  Box,
  GridItem,
  Grid,
  Text,
  Image,
  Flex,
  Center,
  Square,
} from "@chakra-ui/react";
import pic from "./logo.png";

const Layout = () => {
  return (
    <>
      <div>
        <Grid
          templateColumns="repeat(3, 1fr)"
          bg="#005750"
          color={"white"}
          gap={6}
          h="55"
          p={1}
        >
          <GridItem w="100%" h="10">
            <Grid templateColumns="repeat(1, 1fr)" gap={0}>
              <Flex color="white">
                <Image h={12} w={12} src={pic} />

                <Box flex="1">
                  <Text ml={7} fontSize="lg" textAlign={"left"}>
                    Fruit and Vegetable Supply Chains
                  </Text>
                  <Text ml={7} fontSize="sm" textAlign={"left"}>
                    Climate Adaptation & Mitigation Opportunities
                  </Text>
                </Box>
              </Flex>
            </Grid>
          </GridItem>
          <GridItem w="100%" h="10" />

          <GridItem w="100%" h="10">
            <Grid templateColumns="repeat(4, 1fr)" gap={6}>
              <GridItem w="100%" h="10"></GridItem>
              <GridItem w="100%" h="10"></GridItem>

              <GridItem w="100%" h="10">
                <Link to="/home">
                  <Text ml={30} p={3} fontSize={"sm"}>
                    Home
                  </Text>
                </Link>
              </GridItem>
              <GridItem w="100%" h="10">
                <Link to="/">
                  <Text w={40} p={3} fontSize={"sm"}>
                    Analog Tool
                  </Text>
                </Link>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>

        <Outlet />
      </div>
    </>
  );
};

export default Layout;
