import { Flex, Grid, GridItem, Select, Text } from "@chakra-ui/react";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useState } from "react";
import "../App";
import MapAreaCentroid from "./mapAreaCentroid";

import _ from "lodash";
import { useIsMounted } from "../hooks";
import { Analogs } from "./analogs";
import { Help } from "./help";
import { Summary } from "./summary";

let agUrl =
  "https://www.nass.usda.gov/Publications/AgCensus/2017/Online_Resources/County_Profiles/";

export default function Navigate() {
  // the following states are used to select the county
  const [statesData, setStatesData] = useState([]);
  const [selectedState, setSelectedState] = useState(""); //!< here we use the state abbreviation 2 letters code
  const [countiesData, setCountiesData] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(""); //!< here we use the fips code

  // select the scenaio currently targetted
  const [scenariosData, setScenariosData] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState("");

  // analogs data to display current selection data
  const [countyData, setCountyData] = useState(undefined);
  const [analogsData, setAnalogsData] = useState([]);

  // info data is some average data information useful for context
  const [targetInfoData, setTargetInfoData] = useState(undefined);
  const [analogInfoData, setAnalogInfoData] = useState(undefined);

  // const apiServer = "https://fnvcamo.njc-software.com";
  // const apiServer = "http://localhost:8080";
  const apiServer = "https://agclimatechangetools.api.njc-software.com";
  const isMounted = useIsMounted();

  let updateData = false;

  // This effect is used to initialize the list of state
  useEffect(() => {
    if (isMounted.current === true && statesData.length === 0) {
      fetchStates();
    }
  });

  // fetch states
  async function fetchStates() {
    try {
      const states = await axios.get(apiServer + "/state/list", {
        crossdomain: true,
      });
      if (_.isArray(states.data)) {
        setStatesData(states.data);
      } else {
        console.error(`states not loaded status: ${states.status}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // select state event
  const handleSelectState = event => {
    setSelectedState(event.target.value);
  };

  // respond to state change
  useEffect(() => {
    if (selectedState.length > 0) {
      console.log(`selected sate is ${selectedState}`);
      fetchCounties(selectedState);
    }
  }, [selectedState]);

  // fetch counties for the selected state
  async function fetchCounties(state) {
    try {
      const counties = await axios.get(
        `${apiServer}/county/forState/${state}`,
        {
          crossdomain: true,
        }
      );
      if (_.isArray(counties.data)) {
        setCountiesData(counties.data);
      } else {
        console.error(`counties not loaded status: ${counties.status}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // remove useEffect on countiesData dependency on selectedCounty
  const getSelectedCounty = useCallback(counties => {
    let county = _.find(counties, county => selectedCounty === county.fips);
    return county;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // once counties are updated let's reinitialise select selection
  useEffect(() => {
    let county = getSelectedCounty();
    if (county === undefined) {
      console.log(`counties data updated let's reinit selectedCounty`);
      setSelectedCounty("");
    }
  }, [countiesData, getSelectedCounty]);

  // county change event
  const handleSelectCounty = event => {
    setSelectedCounty(event.target.value);
  };

  // respond to county change
  useEffect(() => {
    if (selectedCounty.length > 0) {
      console.log(`selected county is ${selectedCounty}`);
      fetchScenarios();
    }
  }, [selectedCounty]);

  async function fetchScenarios() {
    try {
      const scenarios = await axios.get(`${apiServer}/scenario/rcp`, {
        crossdomain: true,
      });
      setScenariosData(scenarios.data);
    } catch (err) {
      console.error(err);
    }
  }

  // scenario change event
  const handleSelectScenario = event => {
    setSelectedScenario(event.target.value);
  };

  useEffect(() => {
    console.info(
      `update county data ${selectedState} && ${selectedCounty} && ${selectedScenario}`
    );
    if (selectedState && selectedCounty && selectedScenario) {
      const promise = axios.get(`${apiServer}/county/${selectedCounty}`, {
        crossdomain: true,
      });
      promise.then(response => {
        setCountyData(response.data);
      });
      promise.catch(error => console.error(error));
    }
  }, [selectedState, selectedCounty, selectedScenario]);

  const fetchAnalogs = useCallback(() => {
    const promise = axios.get(
      `${apiServer}/analog/county/${selectedCounty}/scenario/${selectedScenario}`,
      {
        crossdomain: true,
      }
    );
    promise.then(response => {
      if (_.isArray(response.data)) {
        setAnalogsData(response.data);
      } else {
        console.error(`analog response data is not in the right format`);
      }
    });
    promise.catch(error => console.error(error));
  }, [selectedCounty, selectedScenario]);

  // TODO: update summary data where the selection change, add a reminder of what data is been seen

  useEffect(() => {
    console.info("update analog data");
    if (selectedState && selectedCounty && selectedScenario) {
      fetchAnalogs();
    }
  }, [selectedState, selectedCounty, selectedScenario, fetchAnalogs]);

  const fetchHistoricalData = async county => {
    try {
      const result = await axios.get(
        `${apiServer}/summary/county/${county}/historical`,
        {
          crossdomain: true,
        }
      );
      setAnalogInfoData(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchHistoricalDataCallback = useCallback(fetchHistoricalData, []);

  const fetchFutureData = async county => {
    try {
      const result = await axios.get(
        `${apiServer}/summary/county/${county}/scenario/${selectedScenario}`,
        {
          crossdomain: true,
        }
      );
      setAnalogInfoData(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchFutureDataCallback = useCallback(fetchFutureData, [
    selectedScenario,
  ]);

  const fetchTargetInfoData = async county => {
    const targetHistorialPromise = axios.get(
      `${apiServer}/summary/county/${county}/historical`,
      {
        crossdomain: true,
      }
    );
    const targetFuturePromise = axios.get(
      `${apiServer}/summary/county/${county}/scenario/${selectedScenario}`,
      {
        crossdomain: true,
      }
    );
    await Promise.all([targetHistorialPromise, targetFuturePromise]);
    setTargetInfoData({
      historical: (await targetHistorialPromise).data,
      future: (await targetFuturePromise).data,
    });
  };

  const fetchTargetInfoDataCallback = useCallback(fetchTargetInfoData, [
    selectedScenario,
  ]);

  // This effect is used to update the data for the selected options
  useEffect(() => {
    if (selectedState && selectedCounty && selectedScenario) {
      setAnalogInfoData(undefined);
      fetchTargetInfoDataCallback(selectedCounty);
    }
  }, [
    selectedState,
    selectedCounty,
    selectedScenario,
    fetchTargetInfoDataCallback,
  ]);

  return (
    <div>
      <Grid
        templateAreas={`"filter help"
                          "map help"
                          "map summary"
                          "analogs analogs"`}
        gridTemplateColumns={"4fr 3fr"}
        gridTemplateRows={"40px 250px 300px 1fr"}
        gap="1"
        bgColor="gray.100"
      >
        <GridItem area={"filter"} bgColor="white">
          <Flex p={0}>
            <Flex
              bgColor={"grey"}
              color={"white"}
              alignItems="center"
              alignContent={"center"}
            >
              <Text size="md" w="100%" pl="6" pr="6">
                TARGET
              </Text>
            </Flex>
            <Select
              value={selectedState}
              onChange={handleSelectState}
              placeholder={"State"}
            >
              {statesData.map(state => (
                <option key={state.code} value={state.code}>
                  {state.name}
                </option>
              ))}
            </Select>
            <Select
              value={selectedCounty}
              placeholder="County"
              onChange={handleSelectCounty}
            >
              {countiesData &&
                countiesData.map(county => (
                  <option key={county.fips} value={county.fips}>
                    {county.county}
                  </option>
                ))}
            </Select>
            <Select
              value={selectedScenario}
              onChange={handleSelectScenario}
              placeholder="Scenario"
            >
              {scenariosData.map(scenario => (
                <option key={scenario.id} value={scenario.id}>
                  {scenario.string}
                </option>
              ))}
            </Select>
          </Flex>
        </GridItem>
        <GridItem pl="2" area={"help"} overflow="auto" bgColor="white">
          <Help />
        </GridItem>
        <GridItem pl="2" area={"summary"} overflow="auto" bgColor="white">
          <Summary targetInfoData={targetInfoData} infoData={analogInfoData} />
        </GridItem>
        <GridItem area={"map"} overflow="visible" bgColor="white">
          <MapAreaCentroid
            apiServer={apiServer}
            county={countyData}
            analogs={analogsData}
          />
        </GridItem>
        <GridItem area={"analogs"} overflow="auto" bgColor="white">
          <Analogs
            countyData={countyData}
            analogsData={analogsData}
            fetchHistoricalData={fetchHistoricalData}
            fetchFutureData={fetchFutureData}
          />
        </GridItem>
      </Grid>
    </div>
  );
}
