import axios from "axios";
import { polygon } from "leaflet";
import _ from "lodash";
import { useRef } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

// let minLat = 0.0;
// let minLong = 0.0;
// let maxLat = 0.0;
// let maxLong = 0.0;

const polyFromCoordinates = geo_shape => {
  var polygons = [];
  if (geo_shape.type === "MultiPolygon") {
    for (var i = 0; i < geo_shape.coordinates.length; i++) {
      var subPolygon = [];
      for (var j = 0; j < geo_shape.coordinates[i][0].length; j++) {
        const lat = geo_shape.coordinates[i][0][j][1];
        const long = geo_shape.coordinates[i][0][j][0];
        // minLat = minLat !== 0.0 ? min([minLat, lat]) : lat;
        // minLong = minLong !== 0.0 ? min([minLong, long]) : long;
        // maxLat = maxLat !== 0.0 ? max([maxLat, lat]) : lat;
        // maxLong = maxLong !== 0.0 ? max([maxLong, long]) : long;
        subPolygon.push([lat, long]);
      }
      polygons.push(subPolygon);
    }
  } else if (geo_shape.type === "Polygon") {
    for (i = 0; i < geo_shape.coordinates[0].length; i++) {
      const lat = geo_shape.coordinates[0][i][1];
      const long = geo_shape.coordinates[0][i][0];
      // minLat = minLat !== 0.0 ? min([minLat, lat]) : lat;
      // minLong = minLong !== 0.0 ? min([minLong, long]) : long;
      // maxLat = maxLat !== 0.0 ? max([maxLat, lat]) : lat;
      // maxLong = maxLong !== 0.0 ? max([maxLong, long]) : long;
      polygons.push([lat, long]);
    }
  } else {
    console.error(
      "Invalid geo_shape provided to polyFromCoordinates function",
      geo_shape
    );
  }
  return polygons;
};

function MapDataComponent(props) {
  const geoShapes = useRef(new Map());
  const polylines = useRef([]);

  const apiServer = props.apiServer;
  const county = props.county ?? undefined;
  const analogs = props.analogs ?? [];

  const mostFrequentAnalog = _.first(analogs) ?? undefined;
  const bestFrequency = mostFrequentAnalog
    ? mostFrequentAnalog.frequency
    : undefined;

  // request all polygons for all states and update MapContainer
  let polygons = _.map(analogs, analog => {
    return {
      fips: analog.analog.fips,
      name: `${analog.analog.county} (${analog.analog.stateAbbreviation})`,
      color: analog.frequency === bestFrequency ? "red" : "black",
    };
  });

  if (county !== undefined) {
    polygons.push({
      fips: county.fips,
      name: `${county.county} (${county.stateAbbreviation})`,
      color: "green",
    });
  }

  const fetchGeoShape = async fips => {
    const geoData = await axios.get(apiServer + `/mapdata/county/${fips}`, {
      crossdomain: true,
    });
    if (_.isObject(geoData.data)) {
      geoShapes.current.set(fips, geoData.data.geoShape);
      return geoData.data.geoShape;
    } else {
      console.error(`geoData not loaded for county ${fips}: ${geoData.status}`);
      return undefined;
    }
  };

  const mapHook = useMap();

  // minLat = minLong = maxLat = maxLong = 0.0;

  const addPolylinesToMap = async () => {
    polygons = await Promise.all(
      _.map(polygons, async poly => {
        if (geoShapes.current.has(poly.fips)) {
          return _.set(
            poly,
            "positions",
            polyFromCoordinates(geoShapes.current.get(poly.fips))
          );
        } else {
          const geoShape = await fetchGeoShape(poly.fips);
          if (geoShape) {
            return _.set(poly, "positions", polyFromCoordinates(geoShape));
          }
        }
      })
    );
    console.log("updating map commponent", polygons);
    polylines.current.forEach(polyline => {
      polyline.remove();
    });

    let bounds = undefined;

    _.each(polygons, poly => {
      const polyObject = polygon(poly.positions, {
        color: poly.color,
      });
      bounds = bounds
        ? bounds.extend(polyObject.getBounds())
        : polyObject.getBounds();
      polyObject.bindTooltip(poly.name).addTo(mapHook);
      polylines.current.push(polyObject);
    });
    if (polygons.length > 0) mapHook.fitBounds(bounds);
  };

  addPolylinesToMap();
  return null;
}

export default function MapAreaCentroid(props) {
  return (
    <MapContainer center={[40.419583, -99.956645]} zoom={4} h="100%">
      <TileLayer
        attribution='&copy; <a href="https://www.osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapDataComponent {...props} />
    </MapContainer>
  );
}
